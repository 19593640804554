import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

import { Column, Container, Row } from '@/components/base/gridview'
import query from '@/services/http/wap/query'
import Iframe from '@/components/base/iframe'
import Anchor from '@/components/base/anchor'
import pageService from '@/services/http/wap/page'
import Image from '@/components/base/image'
import Button from '@/components/base/button/Button'

import { AsgFooterProps } from './types'
import Icon from '@/components/base/icon/Icon'

const AsgFooter: React.FunctionComponent<AsgFooterProps> = (props) => {
  const app = useApp()
  const width = useWidth()
  const router = useRouter()
  const template = app.theme.title
  const [productData, setProductData] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await query.run<any[]>(app.environment, {
          name: 'get-app-glomil-logolar',
          language: app.language,
        })

        if (response) {
          setProductData(response.data)
        }
      } catch (error) {
        console.log('Error:', error)
      }
    }
    fetchData()
  }, [])

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  return (
    <footer
      className={`${props.isHome ? '' : 'not-home'} asg-footer ${
        props.isReverse ? 'asg-footer-reverse' : ''
      }`}
    >
      <Container
        size={
          width < 768
            ? 'extended'
            : template === 'Ana sayfa teması'
            ? 'extended'
            : 'wide'
        }
      >
        {(props.isHome || props.isContact) && (
          <Row className="footer-top-wrapper">
            <Column xl={{ size: 3 }} sm={{ size: 12 }} className="address-bar">
              <span
                className="title"
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['footerTitle'],
                }}
              />
              {props?.addresses?.map((item, index) => (
                <span key={index} className="address">
                  <div dangerouslySetInnerHTML={{ __html: item.address }} />
                </span>
              ))}
              <Anchor href={`mailto:${props.mail}`} className="mail">
                {props.mail}
              </Anchor>
            </Column>
            <Column
              xl={{ size: 9 }}
              sm={{ size: 12 }}
              className={'iframe-wrapper'}
            >
              <Iframe src={props.mapCode} />
            </Column>
          </Row>
        )}
        <Row
          className={`${
            props.isHome || props.isContact ? '' : 'not-home'
          } footer-middle-wrapper`}
        >
          <Column
            xl={{ size: 3 }}
            sm={{ size: 12 }}
            className="footer-logo-wrapper"
          >
            <Anchor className="footer-logo" href={app.settings.homepage}>
              <Image
                {...(props.logo?.src ? props.logo : app.theme.logo)}
                alt={app.page.title}
                height={50}
              />
            </Anchor>
            <span className="info">
              <div
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['copyright'],
                }}
              />
            </span>
            <ul className="language-wrapper">
              {app.languages.map((language) => {
                return (
                  <li key={language.id}>
                    <Button
                      onClick={() => handleLanguageSelection(language.id)}
                    >
                      {language.name.substring(0, 2)}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </Column>
          <Column xl={{ size: 9 }} className={'footer-menu-wrapper'}>
            {props.navigation?.map((item, index) => (
              <div key={index}>
                {item.href === '#' ? (
                  <>
                    <div className="title">{item.title}</div>
                    <ul>
                      {item.children?.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Anchor href={subItem.href}>{subItem.title}</Anchor>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <>
                    <Anchor href={item.href}>{item.title}</Anchor>
                    <ul>
                      {item.children?.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Anchor href={subItem.href}>{subItem.title}</Anchor>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            ))}
          </Column>
        </Row>
        <Row className="asg-footer-firms">
          {productData.map((item, index) => {
            return (
              <Column className="item" key={index}>
                <Image
                  src={item.logo.value.src.label}
                  alt={item.baslik}
                  height={50}
                />
              </Column>
            )
          })}
        </Row>
        <Row className="footer-bottom-wrapper">
          <Column className={'copy-wrapper'}>
            <Anchor href="https://asggrup.com/">
              <span
                style={{ margin: '15px 0', display: 'block' }}
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['asgistiraki'],
                }}
              />
            </Anchor>
          </Column>
          <Column
            xl={{ size: 9 }}
            lg={{ size: 12 }}
            className={'policy-wrapper'}
          ></Column>
        </Row>
        {props.isWhatsapp && props.whatsapptel ? (
          <div className="whatsap-bounce">
            <Anchor
              href={`https://wa.me/+9${props.whatsapptel}`}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh1gpxCTawT2g90fSbQXy7LVHT9Gd5czlT-w&usqp=CAU"
                alt=""
              />
            </Anchor>
          </div>
        ) : null}
      </Container>
    </footer>
  )
}

export default AsgFooter

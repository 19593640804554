import React from 'react'

import type { IFrameProps } from './types'

const IFrame: React.ForwardRefRenderFunction<HTMLIFrameElement, IFrameProps> = (
  props,
  ref
) => {
  if (props.children) {
    return (
      <iframe
        title="iframe"
        ref={ref}
        tabIndex={-1}
        src={String(props.children)}
      />
    )
  } else {
    return <iframe title="iframe" ref={ref} tabIndex={-1} {...props} />
  }
}

export default React.forwardRef(IFrame)
